import React from 'react'
import hapecon from './assets/hapecon.jpg'
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'

import { useState } from 'react'

export default function PersonalForm() {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [zip, setZip] = useState('')
    const [street, setStreet] = useState('')
    const [houseNumber, setHouseNumber] = useState('')
    const [city, setCity] = useState('')
    const [AGB, setAGB] = useState(false)

    const [triedSubmission, setTriedSubmission] = useState(false)
    const [show, setShow] = useState(false)

    const validFormClass = "shadow-sm focus:ring-hapecon-500 focus:border-hapecon-500 block w-full sm:text-sm border-gray-300 rounded-md";
    const invalidFormClass = "shadow-sm focus:ring-hapecon-500 focus:border-hapecon 500 border-red-500 block w-full sm:text-sm rounded-md";

    const validFields = {
        firstName: firstName !== "",
        lastName: lastName !== "",
        phone: phone !== "",
        zip: zip !== "",
        street: street !== "",
        houseNumber: houseNumber !== "",
        city: city !== "",
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setTriedSubmission(true);
        if(!Object.entries(validFields).every(e => e[1])) return;
        let data = {
            firstName,
            lastName,
            email,
            phone,
            zip,
            street,
            houseNumber,
            city
        }
        await fetch('https://alcesp.network/zennioapps/mail', {
            method: 'POST',
            body: JSON.stringify(data)
        }).then((res) => {
            if (res.status === 200) {
                setShow(true);
                setTriedSubmission(false);
                setFirstName("");
                setLastName("");
                setEmail("");
                setPhone("");
                setZip("");
                setStreet("");
                setHouseNumber("");
                setCity("");
                setAGB(false);
            }
        })
    };

    return (
        <form className="space-y-8 divide-y divide-gray-200 mx-auto h-screen mt-6 w-4/5 md:w-1/2 flex flex-col">
            <div className="space-y-8 divide-y divide-gray-200">
                <img src={hapecon} className="sm:w-1/2 mx-auto"/>
                <div className="pt-8">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Persönliche Informationen</h3>
                        <p className="mt-1 text-sm text-gray-500">Bitte geben Sie ihre persönlichen Informationen an, sodass wir Sie kontaktieren können.</p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Vorname
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="first-name"
                                    id="first-name"
                                    autoComplete="given-name"
                                    className={!validFields.firstName && triedSubmission ? invalidFormClass : validFormClass}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Nachname
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    autoComplete="family-name"
                                    className={!validFields.lastName && triedSubmission ? invalidFormClass : validFormClass}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                E-Mail Adresse
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    className={validFormClass}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                Telefonnummer
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    autoComplete="family-name"
                                    className={!validFields.phone && triedSubmission ? invalidFormClass : validFormClass}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="zip" className="block text-sm font-medium text-gray-700">
                                Postleitzahl
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="zip"
                                    id="zip"
                                    autoComplete="postal-code"
                                    className={!validFields.zip && triedSubmission ? invalidFormClass : validFormClass}
                                    value={zip}
                                    onChange={(e) => setZip(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                Stadt
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    autoComplete="city"
                                    className={!validFields.city && triedSubmission ? invalidFormClass : validFormClass}
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Straße
                            </label>
                            <div className="mt-1">
                                <input
                                    id="street-address"
                                    name="street-address"
                                    type="text"
                                    autoComplete="street-address"
                                    className={!validFields.street && triedSubmission ? invalidFormClass : validFormClass}
                                    value={street}
                                    onChange={(e) => setStreet(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="house-number" className="block text-sm font-medium text-gray-700">
                                Hausnummer
                            </label>
                            <div className="mt-1">
                                <input
                                    id="house-number"
                                    name="house-number"
                                    type="text"
                                    className={!validFields.houseNumber && triedSubmission ? invalidFormClass : validFormClass}
                                    value={houseNumber}
                                    onChange={(e) => setHouseNumber(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-8">
                    <fieldset>
                        <div className="space-y-4">
                            <div className="relative flex items-start">
                                <div className="ml-3 text-sm">
                                        <label htmlFor="comments" className="font-medium text-gray-700">
                                            <a href="https://www.hapecon.de/datenschutzerklaerung/">
                                            Ich habe die Datenschutzerklärung gelesen und verstanden.
                                            </a>
                                        </label>
                                </div>
                                <div className="flex items-center h-5 ml-auto">
                                    <input
                                        id="comments"
                                        name="comments"
                                        type="checkbox"
                                        className={(AGB ? "border-gray-300" : "border-red-500") + " focus:ring-hapecon-500 h-4 w-4 text-hapecon-600 rounded"}
                                        checked={AGB}
                                        onChange={(e) => setAGB(!AGB)}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div className="flex justify-end mt-6">
                        {
                            triedSubmission && !Object.entries(validFields).every(e => e[1]) &&
                            <p className="py-2 text-red-500 text-sm italic">Bitte ergänzen Sie die roten Felder und versuche es erneut.</p>
                        }
                        <button
                            type="submit"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-hapecon-600 hover:bg-hapecon-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hapecon-500"
                            onClick={e => handleSubmit(e)}
                        >
                            Speichern
                        </button>
                    </div>
                </div>
            </div>

            <div className="flex-grow flex flex-col">
                <div className="mt-auto mb-4">
                    <a href="https://www.hapecon.de/datenschutzerklaerung/">
                        <p className="py-6 text-sm text-gray-500 text-center">
                            © 2021 Ha Pe Con GmbH. All rights reserved.
                        </p>
                    </a>
                </div>
            </div>
            {
                show &&
                <div className="rounded-md bg-green-50 p-4 absolute top-0 w-4/5 md:w-1/2">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-green-800">Erfolgreich abgeschickt</p>
                        </div>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    type="button"
                                    className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                                    onClick={() => setShow(false)}
                                >
                                    <span className="sr-only">Schließen</span>
                                    <XIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </form>
    )
}
